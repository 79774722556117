import { AxiosError } from 'axios'

type Message = Record<string, string[]>

type Error = {
  message: Message | string
}

export function formatErrorMessage(error: AxiosError<Error>): string {
  const errors = error.response?.data?.message
  if (!errors) return ''
  if (typeof errors === 'string') return errors
  else {
    const message = []
    for (const key in errors) {
      message.push(`${key}: \n${errors[key].join('\n')}`)
    }
    return message.join('\n')
  }
}
