import * as yup from 'yup'

import { passwordSchema } from '../../utils/defaultSchemas'

export const createFormSchema = yup.object({
  fullName: yup.string().required('Nome é obrigatório!.'),
  email: yup
    .string()
    .email('E-mail Inválido.')
    .required('E-mail é obrigatório.')
})

export const updateFormSchema = yup.object({
  email: yup
    .string()
    .email('E-mail Inválido.')
    .required('E-mail é obrigatório.'),
  fullName: yup.string().required('Nome é obrigatório!.')
})

export const passwordFormSchema = yup.object({
  password: passwordSchema('Senha atual é obrigatória.'),
  newPassword: passwordSchema('Nova senha é obrigatória.'),
  newPasswordConfirm: passwordSchema(
    'Confirmação de senha é obrigatória.',
    'newPassword'
  )
})
