import { useState } from 'react'

import { SimpleGrid } from '@chakra-ui/react'

import { useAdmins } from '../../hooks/useAdmins'
import { CreateAdmin } from './CreateAdmin'
import { ListAdmin } from './ListAdmin'
import { SelfUpdate } from './SelfUpdate'
import { UpdatePassword } from './UpdatePassword'

export function Settings(): JSX.Element {
  const [page, setPage] = useState(1)
  const [registerPerPage, setRegisterPerPage] = useState(10)

  const { data, isLoading, isFetching, error, refetch } = useAdmins({
    page,
    per_page: registerPerPage
  })
  return (
    <SimpleGrid
      columns={[1, 1, 2]}
      w="full"
      spacingX="50px"
      spacingY="36px"
      pt="20px"
    >
      <SelfUpdate />
      <UpdatePassword />
      <CreateAdmin onSubmit={refetch} />
      <ListAdmin
        props={{
          page,
          setPage,
          registerPerPage,
          setRegisterPerPage,
          data,
          isLoading,
          isFetching,
          error,
          refetch
        }}
      />
    </SimpleGrid>
  )
}
