import { useEffect, useState } from 'react'

import { Text, Box } from '@chakra-ui/react'

type PasswordValidation = {
  password: string
}

export function PasswordValidation({
  password
}: PasswordValidation): JSX.Element {
  const [validatePassword, setValidatePassword] = useState({
    case: false,
    number: false,
    length: false,
    special: false
  })

  useEffect(() => {
    function passwordTest(): void {
      const regexUppercase = /^(?=.*[A-Z]).*$/
      const regexLowercase = /^(?=.*[a-z]).*$/
      const regexNumber = /^(?=.*[0-9]).*$/
      const regexSpecial = /^(?=.*[^0-9A-Za-z]).*$/
      const length = password.length >= 6

      setValidatePassword({
        case: regexLowercase.test(password) && regexUppercase.test(password),
        number: regexNumber.test(password),
        special: regexSpecial.test(password),
        length
      })
    }
    passwordTest()
  }, [password])

  return (
    <Box mb="4">
      <Text my="2" fontWeight="600" color="black">
        Sua senha deve conter:
      </Text>
      <Text
        fontSize="sm"
        color={validatePassword.length ? 'green.300' : 'grey.200'}
      >
        8 caracteres
      </Text>
      <Text
        fontSize="sm"
        color={validatePassword.case ? 'green.300' : 'grey.200'}
      >
        Uma letra maiúscula e minúscula
      </Text>
      <Text
        fontSize="sm"
        color={validatePassword.special ? 'green.300' : 'grey.200'}
      >
        Um caractere especial (@$!%*#?&)
      </Text>
      <Text
        fontSize="sm"
        color={validatePassword.number ? 'green.300' : 'grey.200'}
      >
        Um número
      </Text>
    </Box>
  )
}
