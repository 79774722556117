import * as yup from 'yup'
import { RequiredStringSchema } from 'yup/lib/string'

export const passwordSchema = (
  message: string,
  ref?: string
): RequiredStringSchema<string | undefined, Record<string, void>> => {
  const regexPassword =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,40})/

  if (ref) {
    return yup
      .string()
      .matches(
        regexPassword,
        'Senha deve ter pelo menos 8 caracteres, letras maiúsculas, números e caracteres especiais (@$!%*#?&)'
      )
      .is([yup.ref(ref)], 'As senhas devem ser iguais.')
      .required(message)
  }
  return yup
    .string()
    .matches(
      regexPassword,
      'Senha deve ter pelo menos 8 caracteres, letras maiúsculas, números e caracteres especiais (@$!%*#?&)'
    )
    .required(message)
}
