import { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Button, Divider, Heading, useToast } from '@chakra-ui/react'
import { FormHandles, SubmitHandler } from '@unform/core'
import axios from 'axios'
import * as yup from 'yup'

import { Form } from '../components/Form/Form'
import { Input } from '../components/Form/Input'
import { PasswordValidation } from '../components/PasswordValidation'
import { useQuery } from '../hooks/useQuery'
import { api } from '../services/api'
import { passwordSchema } from '../utils/defaultSchemas'
import { formatErrorMessage } from '../utils/formatErrorMessage'
import { formatYupError } from '../utils/formatYupError'

type FormData = {
  password: string
  confirmPassword: string
}

const resetFormSchema = yup.object({
  password: passwordSchema('Senha é obrigatório.'),
  confirmPassword: passwordSchema(
    'Confirmação da senha é obrigatório.',
    'password'
  )
})

export function Reset(): JSX.Element {
  const formRef = useRef<FormHandles>(null)
  const history = useHistory()
  const token = useQuery().get('token')
  const [isLoading, setIsLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [isFocused, setFocus] = useState<boolean>(false)

  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: 'top-right'
  })

  const handleSubmit: SubmitHandler<FormData> = async data => {
    setIsLoading(true)
    try {
      await resetFormSchema.validate(data, { abortEarly: false })
      await api.post(`/password/${token}`, data)
      toast({
        title: 'Sucesso!',
        description: 'Senha redefinida com sucesso!',
        status: 'success'
      })
      history.push('/')
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        formRef.current?.setErrors(formatYupError(error))
      }
      if (axios.isAxiosError(error)) {
        toast({
          title: formatErrorMessage(error),
          status: 'error'
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      w="full"
      maxW="400"
      pt="5"
      pb="8"
      px="9"
      boxShadow="md"
      borderRadius="4"
    >
      <Heading fontSize="xl">Redefinir senha</Heading>
      <Divider my="4" />
      <Input
        name="password"
        type="password"
        label="Nova senha"
        placeholder="Informe seu nova senha"
        onChange={e => setPassword(e.target.value)}
        onFocus={() => setFocus(true)}
        onBlur={() => {
          formRef.current?.setErrors({ error: '' })
          setFocus(false)
        }}
        mb="3"
      />
      {isFocused && <PasswordValidation password={password} />}
      <Input
        name="confirmPassword"
        type="password"
        label="Confirmar senha"
        placeholder="Confirme sua nova senha"
        mb="3"
      />

      <Button
        type="submit"
        colorScheme="yellow"
        w="full"
        h="48px"
        mt="7"
        fontSize="lg"
        isLoading={isLoading}
      >
        Atualizar senha
      </Button>
    </Form>
  )
}
